@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800;900&display=swap');

// --------------
// Global 
// --------------

$accent: #61D7B3;
$black: #3D3D3D;
$dark-grey: #575757;
$light-bg: #E9EBEC;
$white: #ffffff;

$font-family: "Poppins", Arial, sans-serif;

$border-radius: 6px;

main {
  padding-bottom: 100px;
}

.container {
  max-width: 750px;
  padding: 0px 20px;
  margin: 0 auto;

  @media (max-width:768px) {
    max-width: 500px;
  }
}

body {
  font-size: 16px;
  background-color: $light-bg;
  font-family: $font-family;
}

h1, h2, h3, h4, h5, p {
  margin-top:0px;
  
  line-height: 1;
  font-weight: 500;
  color: $black;
  
}

a {
  text-decoration: none;
  color: $black;
}

p {
  color: $black;
  font-weight: 500;
  line-height: 24px;
}

.btn {
  text-decoration: none;
  border-radius: $border-radius;
  min-width:200px;
  text-align: center;
  font-weight:500;
  padding:18px 0px;
  background-color: $white;

  &:hover {
    background-color: rgba($white,.7);
  }

  &--accent {
    background-color: $accent;

    &:hover {
      background-color: rgba($accent,.7);
    }
  }

}

.section {
  padding-top:80px;
  margin-bottom: 80px;
  &__header {
    text-align: center;
    font-size:36px;
    margin-bottom:40px;
    align-items: center;
    display: flex;
    justify-content: center;

    svg {
      margin-left:12px;
      font-size:34px;
    }

    svg.wrench-icon {
      font-size: 30px;
    }
  }
  &__description {
    text-align: center;
    margin-bottom:40px;
  }
 
}

.social-icons {
  svg {
    width:20px;
    height:20px;
    margin-right:15px;

    &:hover path {
      fill:$accent;
    }
    
  }
}


// --------------
// Profile Card
// --------------

.profile-card {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  h1 {
    font-size: 46px;
    margin-bottom:5px;
  }

  p {
    font-size: 22px;
    margin-bottom: 15px;
  }

  &__img-circle {
    background-position: center;
    // background-size: 150%;
    background-size: 100%;
    border-radius: 100%; 
    width: 150px;
    height: 150px;
    margin-right:30px;
    // border: 3px solid $accent;
    box-shadow: 4px 4px 8px 2px rgba(61,61,61,.21);
  }

  &__sub-bar {
    display: flex;
    // justify-content: space-between;
    span {
      margin-right:15px;
    }
  }

  &__social-icons {
    // display: flex;
    // justify-content: space-between;
    svg {
      width:20px;
      height:20px;
      margin-right:15px;

      &:hover path {
        fill:$accent;
      }
      
    }

    
  }

  &__location {
    font-size:16px !important;
  }

  

  &__buttons {
    justify-content: center;
    display: flex;
    flex: 0 0 100%;
    margin-top:60px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        margin-left:12px;
        font-size:24px;
      }
    }
    a:first-child {
      margin-right:20px
    }
  }

  @media (max-width:768px) {
    height: 100vh;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;   

    &__sub-bar {
      justify-content: center;
    }
    &__img-circle {
      margin-right:0;
      margin-bottom:20px;
    }

    &__text-content {
      text-align: center;
    }

    &__buttons {
      flex:unset;
      flex-direction: column;

      a.btn {
        margin-right:0;
        margin-bottom:10px;
      }
    }
  }
}

// --------------
// Work 
// --------------

#work .section__description{
    max-width: 78%;
    margin: auto;
}
.work-list {
  padding-top: 20px;
  
  &__item {
    display: flex;
    margin-bottom:40px;
    -webkit-box-shadow: 4px 4px 8px 2px rgba(61,61,61,0.21); 
    box-shadow: 4px 4px 8px 2px rgba(61,61,61,0.21);
    border-radius: 5px;
  }

  &__image {
     flex:0 0 30%;
     border-top-left-radius: $border-radius;
     border-bottom-left-radius: $border-radius;
     background-size: cover;
     background-repeat: no-repeat;
     position:relative;

     &:after {
      content: " ";
      display: block;
      position: absolute;
      left:0;
      right:0;
      bottom:0;
      top:0;
      background-color: rgb(61, 61, 61, 0.2);
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
     }
  }

  &__content {
    flex:1;
    background: $white;
    padding: 40px;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    h3 {
      font-weight:600;
    }
    
    p{
     
      line-height: 1.2;
      margin-bottom:60px;
      white-space: pre-line;
    }

    a {
      display: inline-block;
      min-width: unset !important;
      padding: 10px 20px;
      margin-right: 10px;
    }
  }

  @media (max-width:768px) {
    &__item {
      margin-bottom:30px;
      display: block;
    }

    &__image {
      height: 225px;
      border-top-right-radius: $border-radius;
      border-bottom-left-radius: 0;

    }
    &__image:after {
      border-top-right-radius: $border-radius;
      border-bottom-left-radius: 0;
    }
    &__image[style*="portfolio"] {
      background-position:center;
    }
    &__content {
      padding:25px;
      border-top-right-radius: 0;
      border-bottom-left-radius: $border-radius;
    }

    p {
      margin-bottom:20px;
    }
  }
}

// --------------
// Skills List
// --------------

.skills-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
  margin: auto;

  &__item {
    width:50%;
    font-size: 24px;
    font-weight:500;
    font-family: $font-family;
    line-height:2;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-left:6px;
      font-size: 1.3em;
      // color: $accent;
    }
    @media (max-width:768px) {
      font-size: 18px;
    }
  }
}

// --------------
// Contact Form
// --------------

input, textarea {
  border-style: solid;
  border-color: $dark-grey;
  border-radius: $border-radius;
  border-width: 2px;
  margin-bottom: 15px;
  padding: 10px;
  font-weight: 500;
  font-size: 14px;
}

form {
  max-width:500px;
  width:100%;
  margin: auto;

  label, input, textarea {
    width:100%;
    font-family: $font-family;
    box-sizing:border-box;
    outline-color: $accent;
    
  }

  input:focus, textarea:focus {
    outline-style: solid;

  }

  label {
    font-weight: 500;
    display: block;
    margin-bottom: 10px;
  }

  button {
    border:none;
    cursor: pointer;
    font-family: $font-family;
  }

}

#contact-me {
  .section__description {
    margin-bottom: 20px;
  }
  .social-icons {
    width:100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  button {
    margin-bottom:10px;
  }
}


// --------------
// Footer
// --------------

footer {

  display: flex;
  justify-content: center;
  text-align: center;
  padding: 15px 20px;
  p {
    font-size: 14px;
  }
}